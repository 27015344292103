import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { useCaseStudies, useCatalogues, useDatasheets, useTechnicalResources } from '/src/hooks'

const ResourcesPage = () => {
  return (
    <Layout hero={<HeroImage title="Resources" />} mainContainer={{fluid: "xxl"}}>
      <div className="content-row-container">
        <Row className="d-flex justify-content-center content-row">
          <Col sm={10}>
            <CataloguesRow />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row">
          <Col sm={10}>
            <DatasheetRow />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row">
          <Col sm={10}>
            <TechnicalResourcesRow />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row">
          <Col sm={10}>
            <CaseStudiesRow />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row">
          <Col sm={10}>
            <Row className="d-flex align-items-center">
              <Col md={3}>
                <div className="title-container p-3">
                  <Link to="/resources/greenstar" className="mb-2">
                    <h2 className="title">Sustainability</h2>
                  </Link>
                </div>
              </Col>
              <Col md={9}>
                <Row className="mt-3">
                  <Col sm={6}>
                    <div className="content info">
                      <Link to="/resources/greenstar" className="mb-2">
                        Green Star Credits - Responsible Steel Supply
                      </Link>
                      <p>Green Star certification, by the Green Building Council of Australia, demonstrates that a project has achieved a high standard of design, construction and/or operation and verifies the environmental and social outcomes. A rating system is applied with credits available for best practice across multiple aspects, including materials (https://new.gbca.org.au/).</p>
                    </div>
                  </Col>
                  <Col sm={12}>
                    <Link to="/resources/greenstar">View all greenstar ratings</Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

const TechnicalResourcesRow = () => {
  const sheets = useTechnicalResources()

  return (
    <>
      <Row className="d-flex align-items-center">
        <Col md={3}>
          <div className="title-container p-3">
            <Link to="/resources/technical-resources" className="mb-2">
              <h2 className="title">Product Technical Resources</h2>
            </Link>
          </div>
        </Col>
        <Col md={9}>
          <Row className="mt-3">
            {sheets.map((value, index) => {
              const contents = value.body.map((body) => {
                if (!body || !body.processed) {
                  return ''
                }

                return body.processed.replace(/(<([^>]+)>)/gi, '')
              })
              const summary = contents.join(' ').substring(0, 200)

              return (
                <Col key={index} sm={6}>
                  <div className="content info mb-4">
                    <Link to={value.path} className="mb-2">
                      {value.title}
                    </Link>
                    <p>{summary}...</p>               
                  </div>
                </Col>
              )
            })}

            <Col sm={12}>
              <Link to="/resources/technical-resources">View all Product Technical Resources</Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

const DatasheetRow = () => {
  const sheets = useDatasheets()

  return (
    <>
      <Row className="d-flex align-items-center">
        <Col md={3}>
          <div className="title-container p-3">
            <Link to="/resources/safety-data-sheets" className="mb-2">
              <h2 className="title">Safety Datasheets</h2>
            </Link>
          </div>
        </Col>
        <Col md={9}>
          <Row className="mt-3">
            {sheets.map((value, index) => {
              const contents = value.body.map((body) => {
                if (!body || !body.processed) {
                  return ''
                }

                return body.processed.replace(/(<([^>]+)>)/gi, '')
              })
              const summary = contents.join(' ').substring(0, 200)

              return (
                <Col key={index} sm={6}>
                  <div className="content info mb-4">
                    <Link to={value.path} className="mb-2">
                      {value.title}
                    </Link>
                    <p>{summary}</p>               
                  </div>
                </Col>
              )
            })}

            <Col sm={12}>
              <Link to="/resources/safety-data-sheets">View all Safety Datasheets</Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

const CataloguesRow = () => {
  const catalogues = useCatalogues()

  return (
    <>
      <Row className="d-flex align-items-center">
        <Col md={3}>
          <div className="title-container p-3">
            <Link to="/resources/catalogues" className="mb-2">
              <h2 className="title">Catalogues</h2>
            </Link>
          </div>
        </Col>
        <Col md={9}>
          <Row className="mt-3">
            {catalogues.map((value, index) => {
              const contents = value.body.map((body) => {
                if (!body || !body.processed) {
                  return ''
                }

                return body.processed.replace(/(<([^>]+)>)/gi, '')
              })
              const summary = contents.join(' ').substring(0, 200)

              return (
                <Col key={index} sm={6}>
                  <div className="content info mb-4">
                    <Link to={value.path} className="mb-2">
                      {value.title}
                    </Link>
                    <p>{summary}...</p>               
                  </div>
                </Col>
              )
            })}

            <Col sm={12}>
              <Link to="/resources/catalogues">View all Catalogues</Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

const CaseStudiesRow = () => {
  const caseStudies = useCaseStudies()

  return (
    <>
      <Row className="d-flex align-items-center">
        <Col md={3}>
          <div className="title-container p-3">
            <Link to="/resources/case-studies" className="mb-2">
              <h2 className="title">Case Studies</h2>
            </Link>
          </div>
        </Col>
        <Col md={9}>
          <Row className="mt-3">
            {caseStudies.map((study, index) => {
              const contents = study.body.map((body) => {
                if (!body || !body.processed) {
                  return ''
                }

                return body.processed.replace(/(<([^>]+)>)/gi, '')
              })
              const summary = contents.join(' ').substring(0, 200)

              return (
                <Col key={index} sm={6}>
                  <div className="content info mb-4">
                    <Link to={study.path} className="mb-2">
                      {study.title}
                    </Link>
                    <p>{summary}...</p>               
                  </div>
                </Col>
              )
            })}

            <Col sm={12}>
              <Link to="/resources/case-studies">View all case studies</Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default ResourcesPage

export const Head = () => <title>Resources | Orrcon Steel</title>
